@mixin tripInfo($color) {
	position: relative;
	p{
		margin: 0px;
	}
	span{
		position: absolute;
		top:2px;
		left: 0px;
	}
	&::before{
		position: absolute;
		content: '';
		top: 8px;
		left: 45px;
		font-size: 1.2rem;
		background-color: $color;
		width: 11px;
		height: 11px;
		border-radius: 100%;
	}
}

.map-sidebar-list-container{
	-webkit-box-shadow: 14px 9px 42px -22px rgba(0,0,0,0.75);
	-moz-box-shadow: 14px 9px 42px -22px rgba(0,0,0,0.75);
	box-shadow: 14px 9px 42px -22px rgba(0,0,0,0.75);

	text-align: left;
	width: 448px;
	position: absolute;
	z-index: 999;
	overflow-y: auto;
	max-height: 100%;
	left: 10px;
	top: 10px;

	.card{
		margin: '0px';
		padding: 12px 8px;

		.card-header{
			width:100%;
			margin: 0px;
			padding: 0px;

			button{
				width: 100%;
			}

		}

		.card-body{
			margin-top: 10px;
			padding: 0px;
			text-align: center;

			.list-group{

				width: 100%;
				font-size: 1.2em;
				font-weight: 400;
				margin: 0px;
			
				button{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 0px auto;
					transition: all 0.1s;
					&:hover{
						width: 95%;
					}

				}
	
			}

			.container{
				margin: 20px 0px;
				font-size: 1.4em;
				font-weight: 600;
			}

			.details-collapse-button{
				width: 100%;
			}

			.details{
				width: 100%;
				font-size: 0.8em;
				margin: 10px 0px;
				.list-group{
					display: flex;
					justify-content: space-between;
					border-bottom: 1px solid rgb(207, 207, 207);
					.list-group-item{
						border: none;
					}
				}
			}

			.menu-tabs-container{
				display: flex;
				justify-content: space-around;
				align-items: center;
				padding: 10px;
			}

			.tab-content{
				padding: 20px;
				.list-group{
					text-align: left;
					font-size: 0.9rem;
					div{
						border: none;
					}
				}


				.list-trips {
					.list-trips-item {

						padding: 4px;
						border-radius: 5px;
						transition: all 0.1s ease;
						cursor: pointer;
						.trip-start{
							content: "dsadsa";
							@include tripInfo(#5EE2A0);
						}
						.trip-info{
							position: relative;
							i{
								position: absolute;
								top: 10px;
								left: 44px;
							}
						}
						.trip-end{
							@include tripInfo(#FF6383);
						}

						&:hover{
							background-color: rgb(235, 235, 235);
							font-size: 0.9rem;
						}

						.address{
							padding-left: 65px;
							text-align: start;
						}
						
					}
					.list-trips-item::before{
						content:"";
						display: block;
						position: absolute;
						left: 82px;
					margin-top: 10px;
						width: 1px;
						height: 100px;
						border-left: 1px dashed #A6B1C2;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
  .map-sidebar-list-container{
		width: 100%;
		left: 0px;
		top: 0px;
	}
}
