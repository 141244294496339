.deznav2 {
    width: 17.1875rem;
    /* padding-bottom: 112px; */
    height: 100%;
    position: absolute;
    /* top: 3rem; */
    padding-top: 0;
    z-index: 2;
    background-color: #fff;
    transition: all .2s ease;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1); }
    @media only screen and (max-width: 767px) {
      .deznav2 {
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13); } }
    .deznav2 .deznav2-scroll {
      position: relative;
      height: 100%; }
    @media only screen and (min-width: 1200px) and (max-width: 1350px) {
      .deznav2 {
        width: 14.375rem; } }
    .deznav2 ul {
      padding: 0;
      margin: 0;
      list-style: none; }
    .deznav2 .metismenu {
      display: flex;
      flex-direction: column;
      padding: 10px; }
      .deznav2 .metismenu.fixed {
        position: fixed;
        top: 0;
        width: 100%;
        left: 0; }
      .deznav2 .metismenu > li {
        display: flex;
        flex-direction: column; }
        .deznav2 .metismenu > li a > i {
          font-size: 1.125rem;
          display: inline-block;
          vertical-align: middle;
          padding: 0 0.75rem 0 0;
          font-weight: 700;
          position: relative;
          top: -2px; }
        .deznav2 .metismenu > li > a {
          color: #111;
          font-weight: 600;
          display: inline-block; }
          .deznav2 .metismenu > li > a svg {
            max-width: 20px;
            max-height: 20px;
            height: 100%;
            margin-right: 5px;
            margin-top: -3px;
            color: #2e84e2; }
        .deznav2 .metismenu > li:hover, .deznav2 .metismenu > li:focus {
          box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
          border-radius: 4px; }
          .deznav2 .metismenu > li:hover.nav-label, .deznav2 .metismenu > li:focus.nav-label {
            box-shadow: none; }
          .deznav2 .metismenu > li:hover > a, .deznav2 .metismenu > li:focus > a {
            color: #2e84e2; }
            @media only screen and (min-width: 1200px) {
              .deznav2 .metismenu > li:hover > a, .deznav2 .metismenu > li:focus > a {
                background-color: #fff; } }
        .deznav2 .metismenu > li.mm-active {
          background: #fff;
          border-radius: 4px;
          box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
          padding: 12px 0; }
          .deznav2 .metismenu > li.mm-active > a {
            color: #2e84e2; }
          @media only screen and (max-width: 1199px) {
            .deznav2 .metismenu > li.mm-active {
              padding: 0; } }
          @media only screen and (max-width: 767px) {
            .deznav2 .metismenu > li.mm-active {
              box-shadow: none; } }
          .deznav2 .metismenu > li.mm-active.active-no-child {
            padding: 0; }
      .deznav2 .metismenu li {
        position: relative; }
      .deznav2 .metismenu ul {
        transition: all .2s ease-in-out;
        background: #fff;
        padding: 0 0px 0 30px;
        position: relative;
        z-index: 1; }
        .deznav2 .metismenu ul:after {
          content: "";
          width: 1px;
          background: #eaeaea;
          position: absolute;
          left: 30px;
          height: 100%;
          top: 0;
          bottom: 0;
          z-index: -1; }
        .deznav2 .metismenu ul a {
          font-weight: 300;
          padding-top: .5rem;
          padding-bottom: .5rem; }
          .deznav2 .metismenu ul a:hover, .deznav2 .metismenu ul a:focus, .deznav2 .metismenu ul a.mm-active {
            text-decoration: none;
            color: #2e84e2; }
          .deznav2 .metismenu ul a:before {
            content: "";
            width: 10px;
            position: absolute;
            left: -5px;
            height: 10px;
            border: 2px solid;
            border-radius: 10px;
            background: #fff;
            top: 14px; }
        .deznav2 .metismenu ul ul ul a {
          color: #777; }
      .deznav2 .metismenu a {
        position: relative;
        display: block;
        padding: 0.8125rem 1.25rem;
        outline-width: 0;
        color: #444444;
        text-decoration: none; }
      .deznav2 .metismenu .has-arrow:after {
        width: .5rem;
        height: .5rem;
        right: 1.5625rem;
        top: 48%;
        border-color: inherit;
        -webkit-transform: rotate(-225deg) translateY(-50%);
        transform: rotate(-225deg) translateY(-50%); }
      .deznav2 .metismenu .has-arrow[aria-expanded=true]:after,
      .deznav2 .metismenu .mm-active > .has-arrow:after {
        -webkit-transform: rotate(-135deg) translateY(-50%);
        transform: rotate(-135deg) translateY(-50%); }
  
  @media (max-width: 767px) {
    .nav-header {
      width: 3.75rem; }
      .nav-header .brand-title {
        display: none; }
  
    .footer {
      padding-left: 0; }
  
    .deznav2 {
      left: 0; } }
  /*$primary  : #2e84e2 !default;*/
  /*
  0 - 600: Phone
  600 - 900: Tablet portrait
  900 - 1200: Tablet landscape
  1200 - 1800: Normal styles
  1800+ : Big Desktop
  1em = 16px
  The smaller device rules always should write below the bigger device rules
  Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
  */
  .menu-toggle2 .nav-header {
    width: 3.75rem;
    z-index: 999; }
    .menu-toggle2 .nav-header .brand-logo {
      padding-left: 0;
      padding-right: 0;
      justify-content: center; }
    .menu-toggle2 .nav-header .brand-title {
      display: none; }
    .menu-toggle2 .nav-header .nav-control .hamburger .line {
      background-color: #2e84e2 !important; }
  .menu-toggle2 .header {
    padding-left: 3.75rem;
    width: 100%; }
    [direction="rtl"].menu-toggle2 .header {
      padding: 0 0.9375rem;
      padding-right: 3.75rem; }
  .menu-toggle2 .deznav2 {
    width: 3.75rem;
    overflow: visible;
    position: absolute; }
    .menu-toggle2 .deznav2 .nav-text {
      display: none; }
    .menu-toggle2 .deznav2 .slimScrollDiv,
    .menu-toggle2 .deznav2 .deznav2-scroll {
      overflow: visible !important; }
    .menu-toggle2 .deznav2 .metismenu {
      padding: 5px; }
      .menu-toggle2 .deznav2 .metismenu li {
        position: relative;
        padding: 0; }
        .menu-toggle2 .deznav2 .metismenu li a {
          padding: 0.8125rem 0.9375rem; }
          .menu-toggle2 .deznav2 .metismenu li a svg {
            max-width: 20px;
            max-height: 20px;
            margin-right: 0; }
          .menu-toggle2 .deznav2 .metismenu li a:before {
            content: none; }
        .menu-toggle2 .deznav2 .metismenu li > ul {
          position: absolute;
          left: 3rem;
          top: 2.9375rem;
          width: 12rem;
          z-index: 1001;
          display: none;
          padding-left: 1px;
          height: auto !important;
          box-shadow: 0px 9px 10px 0px rgba(82, 63, 105, 0.1);
          border-radius: 0 0 5px 5px;
          margin-left: 0;
          border: 0; }
          .menu-toggle2 .deznav2 .metismenu li > ul:after {
            content: none; }
          [direction="rtl"].menu-toggle2 .deznav2 .metismenu li > ul {
            left: auto;
            right: 3.75rem; }
          .menu-toggle2 .deznav2 .metismenu li > ul li:hover ul {
            left: 11.8125rem;
            top: 0; }
            .menu-toggle2 .deznav2 .metismenu li > ul li:hover ul:after {
              content: none; }
        .menu-toggle2 .deznav2 .metismenu li:hover > ul {
          display: block;
          height: auto;
          overflow: visible; }
      .menu-toggle2 .deznav2 .metismenu > li {
        transition: all 0.4s ease-in-out; }
        .menu-toggle2 .deznav2 .metismenu > li > a.has-arrow:after {
          display: none; }
        .menu-toggle2 .deznav2 .metismenu > li:hover:nth-last-child(-n + 1) > ul {
          bottom: 2.9375rem;
          top: auto;
          box-shadow: 6px -6px 10px rgba(0, 0, 0, 0.15); }
          [direction="rtl"].menu-toggle2 .deznav2 .metismenu > li:hover:nth-last-child(-n + 1) > ul {
            box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.15); }
        .menu-toggle2 .deznav2 .metismenu > li:hover > a {
          width: 15rem;
          border-radius: 5px 5px 0 5px;
          box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05); }
          [data-theme-version="dark"].menu-toggle2 .deznav2 .metismenu > li:hover > a {
            background: #262932; }
          .menu-toggle2 .deznav2 .metismenu > li:hover > a .nav-text {
            display: inline-block;
            padding-left: 1.25rem; }
            [direction="rtl"].menu-toggle2 .deznav2 .metismenu > li:hover > a .nav-text {
              padding-left: auto;
              padding-right: 1.6875rem; }
        .menu-toggle2 .deznav2 .metismenu > li:hover > ul {
          height: auto !important;
          padding: 10px 0; }
          .menu-toggle2 .deznav2 .metismenu > li:hover > ul a {
            padding: 6px 20px 6px 20px;
            margin-left: -.1rem; }
          .menu-toggle2 .deznav2 .metismenu > li:hover > ul ul {
            padding: 10px 0; }
            .menu-toggle2 .deznav2 .metismenu > li:hover > ul ul a {
              padding: 6px 20px 6px 20px;
              margin-left: -.1rem; }
      .menu-toggle2 .deznav2 .metismenu .nav-label,
      .menu-toggle2 .deznav2 .metismenu .nav-badge {
        display: none; }
  .menu-toggle2 .content-body {
    margin-left: 3.75rem; }
    [direction="rtl"].menu-toggle2 .content-body {
      margin-right: 3.75rem;
      margin-left: auto;
      border: 0; }
  .menu-toggle2 + .footer {
    padding-left: 3.75rem; }
    [direction="rtl"].menu-toggle2 + .footer {
      padding-left: 0;
      padding-right: 3.75rem; }
  
  [data-sidebar-style="full"][data-layout="horizontal"] .deznav2 .metismenu {
    padding: 0 30px; }
  [data-sidebar-style="full"][data-layout="horizontal"] .header .header-content {
    padding-left: 1.875rem; }
  
  @media only screen and (max-width: 1199px) {
    .nav-header {
      width: 3.75rem; }
  
    .footer {
      padding-left: 0; }
  
    .deznav2 {
      left: -100%; }
  
    .content-body {
      margin-left: 0; }
  
    .header {
      padding-left: 3.75rem; }
  
    .menu-toggle2 .deznav2 {
      left: 0; }
      .menu-toggle2 .deznav2 .metismenu > li.mm-active > a,
      .menu-toggle2 .deznav2 .metismenu > li:hover > a {
        background: #fff; } }
  [data-sidebar-style="mini"] .nav-control,
  [data-layout="horizontal"] .nav-control {
    display: none; }